import React from "react";
import { Link } from "gatsby";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import "../styles/pages/_lp.scss";
import "../styles/pages/_overal-styling.scss";
import graph from "../images/lp/TSD_Tagger_Graphs_Hero.png";
import hand from "../images/lp/TSD_Reports_Hero.png";
import graph_graphic from "../images/lp/TSD_Report_Graphic.png";
import circle_graph from "../images/lp/TSD_Tagger_Graphs-1.png";
import square_graph from "../images/lp/TSD_Tagger_Graphs-2.png";
import report_icon1 from "../images/lp/TSD_Report_Icons-03.svg";
import report_icon2 from "../images/lp/TSD_Report_Icons-04.svg";
import report_icon3 from "../images/lp/TSD_Report_Icons-05.svg";

const FreeCreatorinSightReport = () => {
  return (
    <Layout type="marketin-stuck-lp template-lp meltwater-lp">
      <SEO
        title="The Simple Department shares Free Creator and Competitor Insight Reports"
        description="The Simple Department shares Free Creator and Competitor Insight Reports to help companies and brands understand the value and impact of creator campaigns and what their competitors are doing to be successful on social media."
      />
      <section className="pre-paid-design-hero meltwater-hero tagger-hero">
        <div className="container">
          <div className="wrapper">
            <h1>
              Get your customised{" "}
              <span className="red-underline">Signals Report!</span>
            </h1>
            <h3>
              Featuring your top competitors and their best performing social
              content.
            </h3>
            <p>
              Our advanced analytics tools are able to provide you with unique
              insights into what your competitors are doing well + provide you
              with ideas to plan powerful creator campaigns and engaging social
              content that drives REAL RESULTS.
            </p>
            <div className="image-container">
              <div className="image-wrapper">
                <p>Performance over time</p>
                <img src={graph_graphic} className="graphic" />
                <img src={graph} className="graph" />
                <img src={hand} className="hand" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="under-tagger-hero lp-section">
        <div className="container">
          <div className="btn-cta-wrapper">
            <Link to="#reports" className="lp-btn blue-btn btn-blue-outline ">
              Learn More
            </Link>
            <Link
              to="#get_your_report"
              className="lp-btn white-btn-blue-text btn-blue-outline "
            >
              Get Your Report
            </Link>
          </div>
          <div className="flexwrapper meltwater-section1" id="reports">
            <div>
              <img src={circle_graph} />
            </div>
            <div className="order1 leftpadding-large">
              <h2>
                Beyond trend spotting, our report is designed to give you
                actionable insights.
              </h2>
              <p>
                Leveraging our inventory of over 9 billion pieces of content
                from over 7 million Creators and 400k brands, our Signals Report
                allows users to query data using any keyword, hashtag, phrase,
                or Profile Mention.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="lp-section meltwater-section2 howwork">
        <div className="container">
          <div className="flexwrapper" style={{ paddingBottom: "50px" }}>
            <div className="order1 rightpadding-large">
              <h2>
                Our Signals Report will give your content marketing strategy
                super powers.
              </h2>
              <p>
                The Signals Report is data-focused and designed for Business
                Intelligence. Reports are a composition of various components,
                all visualizing key insights drawn from the query’s posts, such
                as:
              </p>
              <ul className="blue-list">
                <li>
                  Where the location of Creators that are included in the query
                </li>
                <li>Which Categories are posting most in the query</li>
                <li>
                  What content types are performing the best and much, much
                  more!
                </li>
              </ul>
            </div>
            <div>
              <img src={square_graph} />
            </div>
          </div>
        </div>
        <div className="slanted-section slanted-meltwater">
          <div className="container">
            <h2
              style={{
                paddingBottom: "30px",
                color: "white",
                textAlign: "center",
              }}
            >
              How Signals Reports Work
            </h2>
            <div className="card-wrapper">
              <div className="card">
                <img src={report_icon1} />
                <h3>Topics</h3>
                <p>
                  Your customised report includes your selected Keywords,
                  Phrases, or Hashtags to help you understand Creator and
                  Content trends around tentpole moments and/or topics.
                </p>
              </div>
              <div className="card">
                <img src={report_icon2} />
                <h3>Mentions</h3>
                <p>
                  Your custom report includes results based on Posts that
                  mention a specific Profile or Profiels and gives you the power
                  to determine who is mentioning your competitors and how often.
                </p>
              </div>
              <div className="card">
                <img src={report_icon3} />
                <h3>Profiles</h3>
                <p>
                  This part of the report covers results based on Posts created
                  by a specific Profile or Profiles, helping you understand how
                  competiting brands speak to and about trending topics,
                  cultural events, campaigns, products and more!
                </p>
              </div>
            </div>
            <div className="btn-wrapper" style={{ display: "none" }}>
              <Link
                to="#"
                className="btn sqr-btn-white"
                style={{ marginTop: "40px" }}
              >
                SEE SOME EXAMPLES!
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section id="get_your_report">
        <div className="form-section">
          <div className="container">
            <h2>Get Your FREE Customised Signals Report</h2>
            <p>
              We build your report around the actions you need to take or plan
              right now. In order to do this, we need to collect you and your
              competitors' social handles, hashtags, and brand/product keywords.
            </p>
            <div className="typeform-btn-wrapper">
              <a className="lp-btn white-btn-blue-text btn-blue-outline ">
                I neeeed it now!!!
              </a>
            </div>
            <p className="typeform-btn-text">
              This will launch a chat with a fancy form to help get all the info
              we need.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default FreeCreatorinSightReport;
